<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item>
                        <el-input v-model='filters.name' placeholder="输入证书名" clearable @clear='handleClear'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            style="width: 85%"
            v-loading="loading"
            :cell-style="rowClass"
            :header-cell-style="headClass"
            @current-change='currentChange'
            :highlight-current-row='true'
            v-if='isAlive'>
            <el-table-column type='index' label='序号' width='60'></el-table-column>
            <el-table-column prop="CertificateName" label="证书名称"></el-table-column>
            <el-table-column prop="CertificateDescription" label="证书描述" show-overflow-tooltip></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="100">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">证书预览</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.PageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.DataCount"
            class='pageBar'>
        </el-pagination>
        <!-- 新增 -->
        <el-dialog
            title="新增证书"
            :visible.sync="addVisible"
            width="50%"
            :close-on-click-modal='false'
            @close='addFormClose'
            >
            <el-form :model='addForm' :rules='addFormRules' ref='addFormRef' label-width='90px'>
                <el-form-item label='证书名称:' prop='CertificateName'>
                    <el-input v-model='addForm.CertificateName' placeholder="请输入证书名称"></el-input>
                </el-form-item>
                <el-form-item label='证书描述:' prop='CertificateDescription'>
                    <el-input v-model='addForm.CertificateDescription' placeholder="请输入描述" type='textarea' class="description"></el-input>
                </el-form-item>
                <!-- <el-form-item label='证书html:'>
                    <el-input v-model='addForm.CertificateHtml' placeholder="请输入证书html代码或不填" type='textarea' class="certifiHtml"></el-input>
                </el-form-item> -->
                <el-form-item label='印章图片:' prop='imageUrl1'>
                    <el-upload
                    :action="uploadImagesUrl"
                    :headers="headers"
                    ref="upload"
                    :before-upload="beforeUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-success="handle_success1"
                    :show-file-list="false"
                    list-type="picture-card"
                    :on-remove="handleRemove1"
                    :file-list="fileList1"
                    >
                        <img v-if="SignetWebImgUrl" :src="SignetWebImgUrl" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div class="imgSpan2">只能上传jpg/png格式图片</div>
                </el-form-item>
                <el-form-item label='背景图片:' prop='imageUrl2'>
                    <el-upload
                    :action="uploadImagesUrl"
                    :headers="headers"
                    ref="upload"
                    :before-upload="beforeUpload"
                    :on-preview="handlePictureCardPreview"
                    :on-success="handle_success"
                    :show-file-list="false"
                    list-type="picture-card"
                    :on-remove="handleRemove2"
                    :on-change="handleChange"
                    :file-list="fileList"
                    >
                        <img v-if="BackgroundWebImgUrl" :src="BackgroundWebImgUrl" class="avatar" />
                        <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div class="imgSpan2">只能上传jpg/png格式图片</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="addFormSubmit" :loading="addLoading">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible45">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <!-- 编辑 -->
        <el-dialog
            title="编辑证书"
            :visible.sync="editVisible"
            width="50%"
            :close-on-click-modal='false'
            @close='editFormClose'
            >
            <el-form :model='editForm' :rules='editFormRules' ref='editFormRef' label-width='90px'>
                <el-form-item label='证书名称:' prop='CertificateName'>
                    <el-input v-model='editForm.CertificateName' placeholder="请输入证书名称" disabled></el-input>
                </el-form-item>
                <el-form-item label='证书描述:' prop='CertificateDescription'>
                    <el-input v-model='editForm.CertificateDescription' placeholder="请输入描述" type='textarea' class="description"></el-input>
                </el-form-item>
                <!-- <el-form-item label='证书html:'>
                    <el-input v-model='editForm.TemplateHtml' type='textarea' placeholder="请输入证书html代码或不填" class="certifiHtml"></el-input>
                </el-form-item> -->
                <el-form-item label='印章图片:' prop='imageUrl1'>
                    <div class="img">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-preview="handlePictureCardPreview"
                        :on-success="handle_success1"
                        :show-file-list="false"
                        list-type="picture-card"
                        :on-remove="handleRemove1"
                        :file-list="fileList1"
                        >
                            <img v-if="SignetWebImgUrl" :src="SignetWebImgUrl" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="imgSpan2">只能上传jpg/png格式图片</div>
                </el-form-item>
                <el-form-item label="背景图片:" prop='imageUrl2'>
                    <div class="img">
                        <el-upload
                            :action="uploadImagesUrl"
                            :headers="headers"
                            ref="upload"
                            :before-upload="beforeUpload"
                            :on-preview="handlePictureCardPreview"
                            :on-success="handle_success"
                            :show-file-list="false"
                            list-type="picture-card"
                            :on-remove="handleRemove2"
                            :on-change="handleChange"
                            :file-list="fileList"
                        >
                            <img v-if="BackgroundWebImgUrl" :src="BackgroundWebImgUrl" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="imgSpan2">只能上传jpg/png格式图片</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="editFormSubmit" :loading="addLoading1">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 证书预览 -->
        <el-dialog
            title="证书预览"
            :visible.sync="certificateViews"
            width="50%"
            :close-on-click-modal='false'
            >
            <div>
                <div v-html="certificateHtml"></div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="certificateViews = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../../util/date';
import Toolbar from "../../components/Toolbar"
import Qs from 'qs'
import { getButtonList } from "../../promissionRouter";
import {getCertificateInfoListPage,addCertificateInfo,updateCertificateInfo,deleteCertificateInfo} from '@/api/api'
import api from '@/api'
export default {
    components:{Toolbar},
    data(){
        var checkCertificateName = (rule,value,callback) => {
            var allCertificateName = this.tableData.map(item => item.CertificateName)
            if(allCertificateName.indexOf(value) == -1){
                return callback()
            }
            else{
                return callback(new Error('该证书已存在！'))
            }         
        }
        return {
            filters:{
                name:''
            },
            uploadImagesUrl: '',
            loading:false,
            currentRow:null,
            //增删改查按钮
            buttonList: [],
            tableData:[],
            addLoading:false,
            addLoading1:false,
            addVisible:false,
            editVisible:false,
            // 表单数据
            addForm:{
                CertificateName:'',
                CertificateDescription:'',
                CertificateHtml: '',
                imageUrl1:'',
                imageUrl2:'',
            },
            editForm:{
                CertificateName:'',
                CertificateDescription:'',
                TemplateHtml: '',
                imageUrl1:'',
                imageUrl2:'',
            },
            needHtml: '',
            // 表单验证规则
            addFormRules:{
                CertificateName:[
                    {required:true,message:'请输入证书名称',trigger:'blur'},
                    {validator:checkCertificateName,trigger:'blur'}
                ],
                CertificateDescription:[{required:true,message:'请输入证书描述',trigger:'blur'}],
                CertificateHtml:[{required:true,message:'请输入证书html',trigger:'blur'}],
                imageUrl1:[{required:true,message:'请上传印章图片',trigger:'blur'}],
                imageUrl2:[{required:true,message:'请上传背景图片',trigger:'blur'}],
            },
            editFormRules:{
                CertificateName:[
                    {required:true,message:'请输入证书名称',trigger:'blur'},
                ],
                CertificateDescription:[{required:true,message:'请输入证书描述',trigger:'blur'}],
                TemplateHtml:[{required:true,message:'请输入证书html',trigger:'blur'}],
                imageUrl1:[{required:true,message:'请上传印章图片',trigger:'blur'}],
                imageUrl2:[{required:true,message:'请上传背景图片',trigger:'blur'}],
            }, 
                // 分页的数据
            pages:{
                DataCount:null,
                PageCount:null,
                PageIndex:1,
                PageSize:20
            },
            imageUrlData: '',
            imageUrlData1: '',
            imageUrlData2: '',
            dialogImageUrl: '',
            dialogVisible45: false,
            fileList:[],
            fileList1:[],
            dialogImageUrlcc: '',
            dialogImageUrlcc1: '',

            //印章
            imageUrlDataStamp: '',
            BackgroundWebImgUrl:'',
            SignetWebImgUrl: '',
            isAlive:true,
            certificateViews: false,
            certificateHtml: '',
        } 
    },
    methods:{
        // 证书预览
        handleClick(val) {
            // var newString = val.TemplateHtml
            var newString = this.needHtml
                .replace('backgroundPic',val.BackgroundWebImgUrl)
                .replace('yinzhangPic',val.SignetWebImgUrl)
                .replace('touxiangPic',require('../../assets/headPortrait.jpg'))
                .replace('erweimaPic',require('../../assets/QRcode.png'))
                .replace('certificateCode','440106000121000021')
                .replace('qualifiedName','韦小X')
                // .replace('cardNum','110422********1939')
                .replace('StationName','企业消防安全责任人、管理人证书')
                .replace('TradeName','医疗机构')
                .replace('registerUnit','xxx信息科技有限公司')
                .replace('registerTimer',this.formatCreateTime(new Date()))
                .replace('sendTimer',this.formatCreateTime(new Date()))
                .replace('shaowStation',"none")
                .replace('shaowStation',"none")
                .replace('noStation',"block")
            this.certificateViews = true
            this.certificateHtml = newString
        },
        // 图片删除函数
        handleRemove1(file, fileList) {
            this.imageUrlDataStamp = ''
        },
        handleRemove2(file, fileList) {
            this.imageUrlData = ''
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible45 = true;
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        handleChange(file, fileList) {
            this.$refs.upload.clearFiles();
            this.$refs.upload.uploadFiles.push(file);
            // this.imageUrl = file.url;
        },
        // 图片上传之前函数
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return false
            }
        },
        // 图片上传成功时的函数
        handle_success(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.imageUrlData = res.Response
                this.BackgroundWebImgUrl = res.Response.weburl
                this.addForm.imageUrl2 = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        handle_success1(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.imageUrlDataStamp = res.Response
                this.SignetWebImgUrl = res.Response.weburl
                this.addForm.imageUrl1 = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 上传图片个数超过限制
        handel_exceed1() {
            this.$message.error('只能上传一张印章图片！')
        },
        handel_exceed2() {
            this.$message.error('只能上传一张背景图片！')
        },
        // 表格表头样式
        headClass () {
            return 'height:20px!important;text-align:center;'
        },
        // 单元格样式
        rowClass () {
            return 'padding:8px 0!important;text-align:center;'
        },
        // 获取当前行
        currentChange(row){
            this.currentRow = JSON.parse(JSON.stringify(row))
        },
        // 筛选input框清空重新请求数据
        handleClear(){
            this.getData()
        },
        // 查询按钮
        getCertificateInfoPaperListPage(){
            this.getData()
        },
        // 新增按钮
        handleAdd(){
            this.imageUrlData = '' 
            this.imageUrlDataStamp = ''
            this.BackgroundWebImgUrl = ''
            this.SignetWebImgUrl = ''
            this.addVisible = true
        },
        // 编辑按钮
        handleEdit(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    showClose:true,
                    message:'选择要编辑的一行数据',
                    type:'error'
                })
            }else{
                this.editForm = this.currentRow
                this.editVisible = true
                this.imageUrlData1 = this.currentRow.BackgroundImgUrl
                this.imageUrlData2 = this.currentRow.SignetImgUrl
                var obj = {}
                var obj1 = {}
                this.editForm.imageUrl1 = 1
                this.editForm.imageUrl2 = 1
                obj.url = this.currentRow.BackgroundWebImgUrl
                obj1.url = this.currentRow.SignetWebImgUrl
                this.dialogImageUrlcc = this.currentRow.BackgroundWebImgUrl
                this.BackgroundWebImgUrl = this.currentRow.BackgroundWebImgUrl
                this.dialogImageUrlcc1 = this.currentRow.SignetWebImgUrl
                this.SignetWebImgUrl = this.currentRow.SignetWebImgUrl
                this.fileList.push(obj)
                this.fileList1.push(obj1)
            }            
        },
        // 删除按钮
        handleDel(){
            var row = this.currentRow
            if(row == null){
                this.$message({
                    showClose:true,
                    message:'选择要删除的一行数据',
                    type:'error'
                })
            }else{
                this.$confirm('确定要删除该行数据吗？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    var params = {ID:row.ID}
                    deleteCertificateInfo(params).then(res => {
                        if(res.data.Success){
                            this.$message({
                                message:res.data.Message,
                                type:'success'
                            })
                            this.loading = false
                            this.getData()
                        }else{
                            this.$message({
                                message:res.data.Message,
                                type:'error'
                            })
                            this.loading = false
                        }
                    })
                }).catch(() => {})
            }
        },
        // 获取页面数据
        getData(){
            this.loading = true
            var params = {
                certificateName:this.filters.name,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize,
            }  // 要传入的参数
            getCertificateInfoListPage(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                    this.loading = false
                    return
                }else{
                    this.loading = false
                }
            
            })
        },
        // 表单提交
        addFormSubmit(){
            this.$refs['addFormRef'].validate(valid => {
                if(valid){
                    if(this.imageUrlData && this.imageUrlDataStamp){}else{
                        this.$message.warning('请上传图片！')
                        return
                    }
                    this.$confirm('确定要添加该证书吗？','提示',{
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // this.addVisible = false
                        this.loading = true
                        var params = {
                            CertificateName:this.addForm.CertificateName,
                            CertificateDescription:this.addForm.CertificateDescription,
                            // TemplateHtml: this.addForm.CertificateHtml?encodeURIComponent(this.addForm.CertificateHtml):encodeURIComponent(this.needHtml),
                            BackgroundImgUrl: this.imageUrlData.webupurl,
                            SignetImgUrl: this.imageUrlDataStamp.webupurl,
                        }
                        // console.log("params",params)
                        // return
                        this.addLoading = true
                        addCertificateInfo(params).then(res => {
                            if(res.data.Success){
                                this.loading = false;
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.reload()
                                this.getData()
                                this.addVisible = false;
                                this.addLoading = false;
                            }else{
                                this.loading = fasle
                                this.addVisible = false;
                                this.$message({
                                    message:res.data.Message,
                                    type:'error'
                                })
                                var _this = this
                                setTimeout(() => {
                                    _this.addLoading = false;
                                },3000)
                            }                           
                        })
                    }).catch(() => {})                  
                }
            })
        },
        editFormSubmit(){
            this.$refs['editFormRef'].validate(valid => {
                if(valid){
                    if(this.imageUrlData1 && this.imageUrlData2){}else if(this.imageUrlData.webupurl && this.imageUrlDataStamp.webupurl){}else{
                        this.$message.warning('请上传图片！')
                        return
                    }
                    this.$confirm('确定要编辑该证书吗？','提示',{
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.loading = true
                        this.editVisible = false
                        var params = {
                            ID:this.currentRow.ID,
                            CertificateName:this.editForm.CertificateName,
                            CertificateDescription:this.editForm.CertificateDescription,
                            // TemplateHtml: this.editForm.TemplateHtml?encodeURIComponent(this.editForm.TemplateHtml):encodeURIComponent(this.needHtml),
                        }
                        if(this.imageUrlData.webupurl){
                            params.BackgroundImgUrl = this.imageUrlData.webupurl
                        }else{
                            params.BackgroundImgUrl = this.imageUrlData1
                        }
                        if(this.imageUrlDataStamp.webupurl){
                            params.SignetImgUrl = this.imageUrlDataStamp.webupurl
                        }else{
                            params.SignetImgUrl = this.imageUrlData2
                        }
                        // console.log("params",params)
                        // return
                        this.addLoading1 = true;
                        updateCertificateInfo(params).then(res => {
                            if(res.data.Success){
                                this.loading = false;
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.getData();
                                this.addLoading1 = false;
                            }else{
                                this.loading = fasle
                                this.$message({
                                    message:res.data.Message,
                                    type:'error'
                                })
                                var _this = this
                                setTimeout(() => {
                                    _this.addLoading1 = false;
                                },3000)
                            }
                        })
                    }).catch(() => {})
                }
            })
        },
        // 表单提交内容清空
        addFormClose(){
            this.$refs['addFormRef'].resetFields()
        },
        editFormClose(){
            this.$refs['editFormRef'].resetFields()
            this.fileList = []
            this.fileList1 = []
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        reload(){
            this.isAlive = false
            this.$nextTick(() => this.isAlive = true)
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    created(){
        this.getData()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.uploadImagesUrl = api.uploadImagesUrl
    },
    mounted() {
        // this.needHtml = '<!DOCTYPE html>'
        //                 +'<html lang="en">'
        //                     +'<head><meta charset="UTF-8">'
        //                         +'<meta name="viewport" content="width=device-width, initial-scale=1.0">'
        //                         +'<meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"/>'
        //                         +'<title>Document</title>'
        //                     +'</head>'
        //                     +'<body style="padding: 0;margin: 0 auto;height: 100vh;">'
        //                         +'<div class="stampBody" style="width: 100%;height: 100%;background-color: #fff;display: flex;flex-direction: column;align-items: center;justify-content: center;">'
        //                             +'<div style="background:url(backgroundPic);background-size:100% 100%;width: 95%;max-width:450px;min-width:355px;height: 275px;border-radius: 20px;position:relative;">'
        //                                 +'<div style="padding: 10px;height:90%;font-family: PingFangSC-Medium, PingFang SC;color: #333333;">'
        //                                     +'<div style="text-align:center;margin-top:22px;">'
        //                                         +'<div style="font-size: 18px;font-weight: bold;line-height: 22px;margin:0 auto;">广东省职业技能培训合格证</div>'
        //                                         +'<div style="font-size: 14px;font-weight: 400;color: #C9151E;line-height: 14px;margin:7px auto 0;">证书编号：<span>certificateCode</span></div>'
        //                                     +'</div>'
        //                                     +'<div style="margin:20px 0 30px 30px;display:flex;flex-direction:column;overflow:hidden;height: calc(100% - 78px);">'
        //                                         +'<div style="display:flex;flex-wrap:nowrap;line-height: 17px;font-size: 13px;font-weight: 400;">'
        //                                             +'<div style="white-space: nowrap;margin-right:12px;">姓名：<u>qualifiedName</u></div>'
        //                                             +'<div style="white-space: nowrap;">身份证号码：<u>cardNum</u></div>'
        //                                         +'</div>'
        //                                         +'<div style="width: 185px;max-width:325px;font-size: 13px;font-weight: 400;line-height: 18px;margin-top:10px;">职业技能：<u>occupation</u></div>'
        //                                         +'<div style="width: 141px;font-size: 13px;font-weight: 400;line-height: 17px;margin-top:10px;">发证日期：<u>sendTimer</u></div>'
        //                                     +'</div>'
        //                                     +'<div style="position:absolute;left:77px;bottom:29px;width:82px;height:82px;">'
        //                                         +'<img src="yinzhangPic" alt="" style="width:82px;height:82px;">'
        //                                     +'</div>'
        //                                     +'<div style="position:absolute;right:130px;bottom:29px;width:65px;height:65px;">'
        //                                         +'<img src="erweimaPic" alt="" style="width:65px;height:65px;">'
        //                                     +'</div>'
        //                                     +'<div style="position:absolute;right:26px;bottom:30px;width:95px;height:120px;">'
        //                                         +'<img src="touxiangPic" alt="" style="width:90px;height:120px;">'
        //                                     +'</div>'
        //                                 +'</div>'
        //                             +'</div>'
        //                         +'</div>'
        //                     +'</body>'
        //                 +'</html>'
        this.needHtml = '<!DOCTYPE html>'
                        +'<html lang="en">'
                        +'<head>'
                            +'<meta charset="UTF-8">'
                            +'<meta name="viewport" content="width=device-width, initial-scale=1.0">'
                            +'<meta name="viewport" content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />'
                            +'<title>证书</title>'
                        +'</head>'
                        +'<body style="padding: 0;margin: 0 auto;height: 100vh;">'
                            +'<div class="stampBody" style="width: 100%;height: 100%;background-color: #fff;display: flex;flex-direction: column;align-items: center;justify-content: center;">'
                                +'<div style="background:url(backgroundPic);background-size:100% 100%;width: 100%;min-width:320px;border-radius: 20px;height: 100vh;position:relative;">'
                                    +'<div style="font-family: PingFangSC-Medium, PingFang SC;color: #333333;width: 95%;height: 96vh;margin: 2vh auto 0;border-radius: 20px;">'
                                        +'<div style="font-family: PingFangSC-Medium, PingFang SC;color: #333333;width: 90%;height: 91.6vh;margin: 0.5vh auto 0;border-radius: 20px;">'
                                            +'<div style="font-family: PingFangSC-Medium, PingFang SC;color: #333333;width: 95%;height: 90vh;margin: 0.5vh auto 0;border-radius: 20px;">'
                                                +'<div style="text-align:center;padding-top:3vh;">'
                                                    +'<div style="font-size: 4.8vh;margin: 0 auto;border-bottom: 2px solid #dbab9c;color: #3f3a39;width: 50vh;">'
                                                        +'<div style="width: 40vh;margin: 0 auto;">'
                                                            +'社会消防安全教育'
                                                        +'</div>'
                                                    +'</div>'
                                                    +'<div style="font-size: 4.8vh;margin:0 auto;color: #3f3a39;text-align: justify;width: 39vh;">'
                                                        +'培训效果合格证<i style="display: inline-block;width: 100%;"></i>'
                                                    +'</div>'
                                                +'</div>'
                                                +'<div style="display:flex;align-items: center;margin: 3vh 0;">'
                                                    +'<div style="width:105px;height:160px;margin-top: -60px;">'
                                                        +'<img src="touxiangPic" alt="" style="width:105px;height:160px;">'
                                                    +'</div>'
                                                    +'<div style="line-height: 17px;font-weight: 400;">'
                                                        +'<div style="white-space: nowrap;margin:-30px 0 20px 20px;font-size: 4vh;color: #dbab9c;">'
                                                            +'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名<span style="margin-left: 20px;font-size: 4.5vh;color: #451414;">qualifiedName</span>'
                                                        +'</div>'
                                                        +'<div style="white-space: nowrap;margin:20px 0 20px 20px;font-size: 4vh;color: #dbab9c;">'
                                                            +'等级<span style="margin-left: 20px;font-size: 4.5vh;color: #451414;">初级</span>'
                                                        +'</div>'
                                                        +'<div style="margin:20px 0 20px 20px;font-size: 3.3vh;line-height: 3vh;color: #dbab9c;">'
                                                            +'证书编号'
                                                            +'<div style="margin-top: 10px;word-break: break-all;color: #451414;line-height: 3vh;">certificateCode</div>'
                                                        +'</div>'
                                                    +'</div>'
                                                +'</div>'
                                                +'<div>'
                                                    +'<div style="font-weight: 400;margin-top:10px;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #dbab9c;padding-bottom: 1vh;">'
                                                        +'<div style="display: shaowStation;">'
                                                            +'<div style="display: flex;justify-content: space-between;font-size: 3.5vh;color: #dbab9c;">'
                                                                +'<div style="width: 10vh;line-height: 5vh;">'
                                                                    +'<div>培训</div>'
                                                                    +'<div>项目</div>'
                                                                +'</div>'
                                                                +'<div style="width: 23vh;line-height: 5vh;">'
                                                                    +'<div>岗位（人群）</div>'
                                                                    +'<div>行业（场所）</div>'
                                                                +'</div>'
                                                            +'</div>'
                                                        +'</div>'
                                                        +'<div style="display: noStation;">'
                                                            +'<div style="width: 25vh;font-size: 3.5vh;color: #dbab9c;">培训项目</div>'
                                                        +'</div>'
                                                        +'<div style="text-align: right;font-size: 3.1vh;line-height: 5vh;color: #451414;">'
                                                            +'<div>StationName</div>'
                                                            +'<div style="display: shaowStation;">TradeName</div>'
                                                        +'</div>'
                                                    +'</div>'
                                                    +'<div style="font-weight: 400;margin: 3vh 0;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #dbab9c;padding-bottom: 1vh;">'
                                                        +'<div style="width: 25vh;font-size: 3.5vh;color: #dbab9c;">登记单位</div>'
                                                        +'<span style="text-align: right;font-size: 3.1vh;line-height: 5vh;color: #451414;">registerUnit</span>'
                                                    +'</div>'
                                                    +'<div style="font-weight: 400;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #dbab9c;padding-bottom: 1vh;">'
                                                        +'<div style="font-size: 3.5vh;color: #dbab9c;">登记日期</div>'
                                                        +'<span style="font-size: 3.5vh;color: #451414;">registerTimer</span>'
                                                    +'</div>'
                                                +'</div>'
                                                +'<div style="display: flex;justify-content: space-around;margin-top: 4vh;">'
                                                    +'<div style="width: 50%;height:120px;">'
                                                        +'<img src="erweimaPic" alt="" style="width:120px;height:120px;">'
                                                    +'</div>'
                                                    +'<div style="width: 50%;height:120px;position: relative;">'
                                                        +'<div style="width: 100%;height: 120px;display: flex;flex-direction: column;align-items: center;justify-content: space-around;">'
                                                            +'<div></div>'
                                                            +'<div style="font-size: 4vh;font-weight: bold;color: #3f3a39;">sendTimer</div>'
                                                            +'<div style="font-size: 2.3vh;color: #3f3a39;">评价单位：广东省消防协会</div>'
                                                            +'<div></div>'
                                                            +'<div></div>'
                                                        +'</div>'
                                                        +'<div style="position: absolute;top: 0;width: 100%;">'
                                                            +'<div style="display: flex;justify-content: center;width: 100%;">'
                                                                +'<img src="yinzhangPic" alt="" style="width:120px;height:120px;">'
                                                            +'</div>'
                                                        +'</div>'
                                                    +'</div>'
                                                +'</div>'
                                            +'</div>'
                                        +'</div>'
                                    +'</div>'
                                +'</div>'
                            +'</div>'
                        +'</body>'
                        +'</html>'
    }
}
</script>

<style lang="stylus" scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .certifiHtml,.certifiHtml>>>.el-textarea__inner{
        height 150px;
    }
    .description,.description>>>.el-textarea__inner{
        height 150px;
    }
</style>
<style>
    .el-tooltip__popper {
        max-width: 500px;
    }
</style>